import React from "react";
import dynamic from "next/dynamic";
import { MdStar } from "react-icons/md";
import { ReviewFieldsType } from "types/business.types";
import { PaginateGenericType } from "types/generic.types";
import useTranslation from "next-translate/useTranslation";
import { useBusinessProfile } from "hooks/useFetch";

const ReviewsList = dynamic(() => import("./List.reviews"), {
	ssr: true,
});

type Props = { reviews: PaginateGenericType<ReviewFieldsType> };

export default function ReviewsContainer({ reviews }: Props) {
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data;
	const { t } = useTranslation("common");

	if (reviews.items.length > 0) {
		const overall = t("sections.reviews.overall", { count: widgetSetting?.rate?.score || 0 });
		const customerRating = t("sections.reviews.customerRating", {
			count: widgetSetting?.rate?.count || 0,
			plural: widgetSetting?.rate.count && widgetSetting?.rate.count > 1 ? "s" : "",
		});
		return (
			<>
				<div className="flex items-start space-x-2 mb-4">
					<MdStar className="text-primary-500 w-6 h-6 xl:w-8 xl:h-8" />
					<h2 className="font-medium text-lg xl:text-xl mb-4 flex flex-col">
						<span>{overall}</span>
						<span className="text-[#8F9094] text-base font-normal">{customerRating}</span>
					</h2>
				</div>

				<ReviewsList />
			</>
		);
	} else {
		return null;
	}
	// else {
	// 	return <EmptyState section="reviews" />;
	// }
}
